<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <b-card class="mb-0">
        <b-link class="brand-logo">
          <!-- <vuexy-logo /> -->
          <img
            :src="imgUrl"
            height="100"
          >

          <!-- <h2 class="brand-text text-primary ml-1">
            Vuexy
          </h2> -->
        </b-link>

        <b-card-text class="text-center mb-2">
          Bienvenid@, por favor ingresa con tus credenciales
        </b-card-text>

        <!-- form -->
        <validation-observer
          ref="loginValidation"
          #default="{invalid}"
        >
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent
          >

            <!-- email -->
            <b-form-group
              label-for="email"
              label="Usuario"
            >
              <validation-provider
                #default="{ errors }"
                name="Usuario"
                rules="required"
              >
                <b-form-input
                  id="login-email"
                  v-model="userEmail"
                  name="login-email"
                  :state="errors.length > 0 ? false:null"
                  placeholder="john"
                  autofocus
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- password -->
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="password">Contraseña</label>
              </div>
              <validation-provider
                #default="{ errors }"
                name="Contraseña"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="login-password"
                    v-model="password"
                    :type="passwordFieldType"
                    class="form-control-merge"
                    :state="errors.length > 0 ? false:null"
                    name="login-password"
                    placeholder="Contraseña"
                  />

                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- submit button -->
            <b-button
              variant="primary"
              type="submit"
              block
              :disabled="invalid"
              @click="validationForm"
            >
              Ingresar
            </b-button>
          </b-form>
        </validation-observer>

      </b-card>
    <!-- /Login-->
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BCardText, BForm, BButton,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// eslint-disable-next-line import/no-cycle
import router from '@/router/index'

export default {
  components: {
    BCard,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BCardText,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '',
      userEmail: '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      // validation rulesimport store from '@/store/index'
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    validationForm() {
      this.$refs.loginValidation.validate().then(async success => {
        if (success) {
          const request = {
            url: '/api/users/login',
            method: 'POST',
            data: {
              email: this.userEmail,
              password: this.password,
            },
          }
          const respRoles = await store.dispatch('back/EXECUTE', request)
          console.log('respRoles', respRoles)
          if (respRoles.token) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Bienvenido',
                icon: 'EditIcon',
                variant: 'success',
              },
            })
            localStorage.setItem('accessToken', respRoles.token)
            localStorage.setItem('userData', JSON.stringify(respRoles))
            router.push({ name: 'facturador' })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: respRoles.message,
                icon: 'AlertCircleIcon',
                variant: 'danger',
              },
            })
          }
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
